.PostShare{
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    background-color: var(--cardColor);
    padding: 1rem;
    border-radius: 1rem;

    .ProfilePicAndInput{
        // border: 1px solid green;
        display: flex;
        gap:1rem;
        max-width: 100%;
        // overflow: hidden;
        .ProfileImageContainer{
            flex: 0.1;
            // border: 1px solid green;
        
            .profileImage{
                border-radius: 50%;
                width: 3rem;
                height: 3rem;
                object-fit: cover;
            }
        }
    
        .CreatePost{
            flex: 0.9;
        
            .TextInputPostShare {
                margin-bottom: 1rem;
                .tagsInput{
                    border: none;
                    outline: none;
                    width: 100%;
                    font-size: 14px;
                    padding: 0.5rem;
                    background-color: transparent;
                    margin-top: 0.5rem;
                    color: #3d99e9;
                    font-weight: 600;
                }
            }
            
        
            .PostOptions{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                line-height: 2.5rem;
                // border: 1px solid black;
                max-width: 100%;
        
                .option{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    gap: 0.2rem;
                    img{
                        height:1.35rem;
                        width:1.35rem;
                    }

                    &:hover{
                        cursor: pointer;
                    }
                }
                
            }
        }
    }
}


@media only screen and (max-width: 600px) {
    
    .PostShare{
        padding: 0rem;

        .ProfilePicAndInput{
            // border: 1px solid;
            padding-bottom: 1rem;

            .CreatePost{
                flex:1;
                 
    
                .TextInputPostShare {
                    padding: 1rem;
                    margin-bottom: 0rem;
                }
    
                .PostOptions {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
      
                    // border: 1px solid;
                    // padding: 1rem;

                    button{
                        margin-top: 0.5rem;
                        // width: 95%;
                    }

                   
                    padding-left: 1rem;
                    .option{
                        gap: 0.6rem;
                        // border:1px solid red;
                        justify-content: flex-start;
                    }
                }
            }
        }
    
    }
    
}


.Preview{
    display: flex;
    gap:1rem;
    // border:1px solid red;
}
.Preview>input{
    background-color: var(--inputColor);
    border-radius: 10px;
    padding: 10px;
    font-size: 17px;
    border:none;
    outline: none;
}



.Preview{
    display: flex;
    justify-content: center;
}

.PreviewImageShare{
    display: flex;
    justify-content:  flex-end;
    position: relative;
    margin-top: 1rem;
    // max-width: 100%;
    // max-height: 100%;
    // border:1px solid blue;
    
}
.PreviewImageShare>svg{
    position: absolute;
    right:1rem;
    top:0.5rem;
    cursor: pointer;
}
.PreviewImageShare>img{
    width: 100%;

    max-height: 20rem;
    // object-fit: cover;
    border-radius: 0.5rem;
    object-fit: contain;

}
.PreviewVideo{
    display: flex;
    justify-content: center;
    padding:0.5rem;
    width:100%;
    gap:1rem

}
.PreviewDocument{
   
    border-radius: 1rem;
    background-color: var(--cardColor);
    display: flex;
    gap:1rem;
    align-items: flex-start;

}
.PostPreview{
    display: flex;
    gap:1rem;
    justify-content: center;
    flex: 1;
}
.PostTags{
    display: flex;
    margin-top: 1rem;
    gap:1rem
}
.PostTags>small{
    display: flex;
    color:blue;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
   
}
