.friendsModal{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 80vh;
    // border: 1px solid blue;
   
}
.header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // border: 1px solid;
    gap: 1rem;


}
// .shareButton{
//     // border: 1px solid red;
//     display: flex;
//     justify-content: flex-end;
    
//     // position: relative;

// }
.buttonsAndInput{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // border:1px solid

}
.shareButtons{
    display: flex;
    justify-content: flex-end;
    gap:1rem
  
}

.slectedUsers{
    display: flex;
    flex-direction: row;
    height:3rem;
    // border:1px solid;
    gap: 1rem;
    white-space: nowrap;
    overflow-x: scroll;
}
.slectedUsers>small{
    color: var(--gray);
}
.friendsList{
    display: flex;
    flex-direction: column;
    gap:0.3rem;
    overflow: scroll;
    // max-height: 60%;
    // border: 1px solid red;
}


