.viewPostModal{
    display: grid;
    // border: 1px solid;
    max-height: 65vh;
    grid-template-columns: 65% 35%;
    gap: 0.5rem;
    overflow: scroll;
}

.postImage{
    // border: 1px solid red;
    display: flex;
    max-height: 65vh;
    justify-content: center;
    align-items: center;
    
}

.postImage>img{
    // width: 100%;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto\9;
    // object-fit: contain;
    border-radius: 0.5rem;

}

.postDetails{
    display: flex;
    flex-direction: column;
    gap:0.5rem;
    // border: 1px solid blue;
    // background-color: red;
    max-height: 65vh;
}
.nameAndImage{
    display: flex;
    flex-direction: column;
    // gap:0.5rem;
    // justify-content: flex-end;
    // align-items: flex-start;
    // border: 1px solid blue;
}
.nameAndImage>small{
    font-size: 12px;
    color: var(--gray);
    // margin-left: 1rem;
}

.description{
    font-size: small;
    line-height: 1.6;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

    // border: 1px solid green;
}

.LikesAndComments{
    margin-top: 0.5rem;
}
.postContainer{
    display: flex;
    // align-items: center;
    // justify-content: center;
    // border:1px solid;
    flex: 1;
}