.TrendCard {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--cardColor);
  padding: 1rem;
  border-radius: 1rem;
  padding-left: 1rem;
  overflow: scroll;
  max-height: 70vh;
}

.friendsHeading {
  cursor: pointer;
}/*# sourceMappingURL=TrendCard.css.map */