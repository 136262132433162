.mediaMessage {
  color: white;
  padding: 0;
  border-radius: 1rem 1rem 1rem 0rem;
  max-width: 28rem;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: black;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  z-index: -1;
}

.forwardSign {
  height: 0.5rem;
  width: 0.5rem;
}

.mediaMessage:hover {
  cursor: pointer;
  box-shadow: 5px 10px 18px #d4d4d4;
  transition: all 0.4s;
}

.mediaOwn {
  align-self: flex-end;
  border-radius: 1rem 1rem 0rem 1rem;
}

.message {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  position: relative;
}

.menuButton {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  border-radius: 50%;
}

.messageImage {
  position: relative;
}

.messageImage > img {
  border-radius: 1rem 1rem 0rem 0rem;
  width: 20rem;
  height: auto;
  z-index: 0;
}

.messageImage > embed {
  border-radius: 1rem 1rem 0rem 0rem;
  width: 20rem;
  height: auto;
  z-index: 0;
}

.videoPlayerMessage {
  border-radius: 1rem 1rem 0rem 0;
  width: 25rem;
  height: auto;
  position: relative;
  overflow: hidden;
}

.messageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.attachmentDetails {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.messageDetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding-bottom: 0.3rem;
  overflow-wrap: break-word;
}

.messageDetail > div {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.messageDetail.media {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.messageTime {
  font-size: 0.7rem;
  color: var(--textColor);
  align-self: bottom;
}

.IconButton-root {
  color: #20b2aa;
}/*# sourceMappingURL=message.css.map */