.Home {
    position: relative;
    display: grid;
    grid-template-columns: 25% auto 25%;
    gap: 1rem
}
@media only screen and (max-width: 600px) {
    .Home {
        grid-template-columns: 100%;
        gap: 0rem
 
    }
    .NavBarContainer{
        position: fixed;
        width: 100%;
        height: 4rem;
        background-color: rgba(221, 221, 221, 0.64);
        border-radius: 1rem 1rem 0 0;
    
        backdrop-filter: blur(15px);
        bottom: 0rem;
        left:0;
        // border: 1px solid rgb(191, 191, 191);
        display: flex;


    }
    .SearchContainer{
        position: fixed;
        width: 100%;
        background-color: rgba(221, 221, 221, 0.64);
        border-radius: 0rem 0rem 1rem 1rem;
        z-index:1;
        backdrop-filter: blur(15px);
        top:0rem;


    }
  


}
