.motherElement{

    gap: 1rem;
    // border:2px solid red;
    display:flex;
    flex: 1;
    // justify-content: center;
}



.Post{
    display: flex;
    flex-direction: column;
    padding:1rem;
    background-color: var(--cardColor);
    border-radius: 1rem;
    gap: 1rem;
    flex:1;
    // border: 1px solid;
    overflow: hidden;
    max-width: 100vw;

}
.PostView{
    flex: 1;
    // border: 2px solid
    // background-color: green;
}
.PostInfo{
    display: flex;
    flex-direction: column;
    gap:1rem;
    // border:1px solid



}

.PostInfo:hover{
    .EditButton{
        cursor: pointer;
        display: flex;
        }
}

.sharePostDetails{
    display: flex;
    justify-content: space-between;
    align-items: center; 
}
.sharePostDetails>small{
    font-size: 12px;
    color: var(--gray);

}


.PostHeader{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    // border: 1px solid red;

}
.PicAndName{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.7rem;
    // border: 1px solid blue;
}
.PostHeader>small{
    font-size: 12px;
    color: var(--gray);
    align-items: flex-end;
}


.PicAndName>img{
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    
}
.PicAndName>div{
    display: flex;
    flex-direction: column;
}
.PicAndName>div>span{
    font-weight: 600;
}
.time{
    font-size: 12px;
    color: var(--gray);
}

.detail{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    line-height: 1.6;
    font-size: 14px;
    // border:1px solid;
}
.EditButton{
    display: flex;
}


.EditButton>small {
    font-size: 12px;
    color: var(--gray);
    align-items: flex-end;
}
.editOptions{
    display: flex;
    
}


.PostImage {
    // position: relative;
    display: flex;
    gap: 1rem;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    // border:1px solid blue;

}

.videoPlayer{
    // border: 1px solid red;
    overflow: hidden;
    border-radius: 1rem;
    object-fit: contain;

}




.PostImageView{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    // width: 100%;
    // flex-grow: 2;
    // border:2px solid blue;
    // background-color: red;
}

.PostImageView>img {
    // width: 100%;
    max-width: 100%;
    max-height: 20rem;
    height: auto;
    width: auto\9;
    object-fit: contain;
    border-radius: 0.5rem;

}


.PostImage>img {
    // width: 100%;
    max-width: 100%;
    max-height: 20rem;
    object-fit: contain;
    border-radius: 0.5rem;
    height: auto;
    width: auto\9;

}

.PostImage>svg {
    position: relative;
    right: 1rem;
    top: 0.5rem;
    cursor: pointer;
}

.postBottom{
    display: flex;
    flex-direction: column;
    gap:1rem;
    // border: 1px solid green
}

.LikesAndComments{
    display: flex;
    gap: 0.5rem;
    // padding-left: 0.5rem;
}

.PostReact{
    display: flex;
    align-items:center;
    // justify-content: center;
    gap:1rem;
    // border:1px solid;

}
.PostReact>img{

    cursor: pointer;

}
.PostReact>div {
    cursor: pointer;
    color: var(--gray);
    &:hover {
        color: black;
        transition: all 0.4s;
        }
    }
.CommentSection{
    display: flex;
    flex-direction: column;

}

.newComment{
    display: flex;
    align-items: center;
    // transition:/
    transition-property: all;
    transition-duration: 3s;
    // border: 1px solid;
}

.newComment> div{
    // border: 1px solid;
    width: 100%;   
}

.comment-button{
    margin-left: 1.2rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    padding: 0.3rem;
}

.newComment>img{
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
}
.previousComments{
    display: flex;
    flex-direction: column;
    gap:1rem;
    margin-top: 1rem;
    // border: 1px solid;

}