.mediaMessage {

    // background: var(--buttonBg);
    color: white;

    padding: 0;
    border-radius: 1rem 1rem 1rem 0rem;
    max-width: 28rem;
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: black;
    // border-radius: 1rem;
    // position: relative;
    // background: #e9e8e8;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    z-index:-1;
    // border:1px solid;

}


.forwardSign{
    height: 0.5rem;
    width:0.5rem
}


.mediaMessage:hover{
    cursor: pointer;
    // background: #cccccc;
    // background-color: #f6f6f6e2;
    box-shadow: 5px 10px 18px #d4d4d4;
    transition: all 0.4s;
  
}

.mediaOwn {
    align-self: flex-end;
    border-radius: 1rem 1rem 0rem 1rem;

}

.message{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    position: relative;
    // background-color: red;

}


.menuButton{
    position: absolute;
    top:0;
    right:0;
    z-index:5;
    border-radius: 50%;

}
.messageImage{
    position: relative;
}

.messageImage>img{
    // border-radius: 1rem;
    border-radius: 1rem 1rem 0rem 0rem;
    width: 20rem;
    height: auto;
    // position: relative;
    z-index: 0;
   
}
.messageImage>embed {
    // border-radius: 1rem;
    border-radius: 1rem 1rem 0rem 0rem;
    width: 20rem;
    height: auto;
    // position: relative;
    z-index: 0;

}
.videoPlayerMessage {
    // border-radius: 1rem;
    border-radius: 1rem 1rem 0rem 0;
    width: 25rem;
    height: auto;
    position: relative;
    overflow: hidden;

}
.messageContainer{
    // max-width:18rem;

    width:100%;
    display: flex;
    flex-direction: column;
    gap:0.5rem;
    // border: 1px solid blue;
}



.attachmentDetails{
    display: flex;
    gap:1rem;
    width: 100%;
    
}
// .chatBody{
//     background-color: #89baf2
// }


// .attachmentDetails>img {
//     width: 5rem;
//     height: auto
// }
.messageDetail{
    display: flex;
    // border: 1px solid red;
    // width:100%;
    // flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // align-items: flex-end;
    gap:1rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    padding-bottom: 0.3rem;
    overflow-wrap: break-word;

}

.messageDetail>div{
    display: flex;
    gap:0.5rem;
    // flex-direction: column;
    align-items: center;
}

.messageDetail.media{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}



.messageTime {
    font-size: 0.7rem;
    color: var(--textColor);
    align-self: bottom;
    // border:1px solid

}

.IconButton-root {
    color: #20b2aa;
}