.attachedPost{
    display: flex;
    flex-direction: column;
    padding:0.5rem;
    border-radius: 1rem;

    box-shadow: inset 0 0 6px;

}
.PicAndName{
    display: flex;
    justify-content: flex-start;
}