.FollowersCard {
  width: 100%;
  border-radius: 1.5rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  background-color: var(--cardColor);
  padding: 0.5rem;
  height: 50vh;
  overflow-y: scroll;
}

.user {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.follower {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.follower > div {
  display: flex;
  gap: 10px;
}

.followerIamge {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
}

.name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.name > span:nth-of-type(1) {
  font-weight: bold;
}

.fc-button {
  height: 2rem;
  padding-left: 20px;
  padding-right: 20px;
}
.fc-button :hover {
  cursor: pointer;
}

.UnfollowButton {
  color: var(--orange);
  border: 2px solid var(--orange);
  cursor: pointer;
  background: transparent;
}/*# sourceMappingURL=FollowersCard.css.map */