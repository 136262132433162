.RightSide{
    display: flex;
    flex-direction: column;

    gap:2rem;

    
}
.NavIcons{
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
    // position: relative;

}
.NavIcons>img{
    width: 1.3rem;
    height: 1.3rem;
}
.NavIcons>a>img {
    width: 1.3rem;
    height: 1.3rem;
}
.r-button{
    height: 3rem;
    width: 80%;
    align-self: center;

}
.signOut{
    cursor: pointer;
}
a {
    text-decoration: none;
}