.ProfileCard{

    border-radius: 1.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 0rem;
    overflow-x: clip;
    background-color: var(--cardColor);
    // border: 1px solid;

}

.ProfileImages{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    // border:3px solid blue;
    // height: 30%;
    // max-height: 50%;


}
.ProfileImages>img:nth-of-type(1) {
    width:100%;
    max-width: 100%;   
    // border: 2px solid blue; 
}

.profileCover{
    max-height: 25rem;
}

.visibilityAndFollowButton{
    // border:1px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:1rem
}
.visibilityIcon{
    margin-left: 1rem;

}

.visibilityIcon>img{
    height:1.5rem;
    width:1.5rem;
}

.followButton{
    align-self: flex-end;
    
    margin-right: 0.5rem;
    margin-top: 1rem;

}

@media only screen and (max-width: 600px) {

    .editButton {
        margin-top: 1rem;

    }
    .profileCover {
        max-height: 15rem;

    }
    .followButton {
        margin-top: 1rem;
    }

}
.visibilityToggle{
    // cursor: pointer;
    display: flex;
    align-items: center;
    // background-color: red;

    // gap:1rem

    
}

.ProfileImages>img:nth-of-type(2) {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    position: absolute;
    bottom:-3rem;
    box-shadow: var(--profileShadow);
    object-fit: cover;
}
.ProfileName{
    display: flex;
    flex-direction: column;
    align-items: center;
    // border: 1px solid blue;
    gap: 10px;
}

@media only screen and (min-width: 800px) {

    .ProfileName {
        position: relative;
        margin-top: 1rem;
        // max-width: 500px;
        // border:1px solid;
    }

}

.ProfileName>span:nth-of-type(1){
    font-weight: bold;
}

.FollowStatus{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.75em;
}

.FollowStatus>hr{
    width: 75%;
    border:1px solid var(--hrColor)

}
.FollowStatus>div{
    display: flex;
    gap:1rem;
    width: 80%;
    justify-content: space-around;
    align-items: center;
}
.Follow{
    display: flex;
    flex-direction: column;
    gap:0.3rem;
    align-items: center;
    justify-content: center;
}
.Follow>span:nth-of-type(1){
    font-weight: bold;
}
.Follow>span:nth-of-type(2) {
    color:var(--gray);
    font-size: 13px;
}

.vl{
    height: 150%;
    border-left: 2px solid var(--hrColor)
}
@media only screen and (max-width: 600px) {

    .vl{
        height: 110%;
    }
    .FollowStatus{
        gap: 0.5rem;

    }
}

.ProfileCard>span{
    font-weight: bold;
    color: orange;
    align-self: center;
    margin-bottom: 1rem;
    // border:10px solid black;
    cursor: pointer;
}