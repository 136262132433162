.PostSide{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100vh;
    overflow: auto;
    position: relative;
    // background-color: red;
}
@media only screen and (max-width: 600px) {
    .postShare {
            position: fixed;
            left: 0;
            top:4rem;
      
            background-color: white;
            box-shadow: 5px 10px 18px black;
            z-index: 1;
            width: 100%;

        }
 

}
