.InfoCard {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  background-color: var(--cardColor);
  padding: 1rem;
  border-radius: 1rem;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .InfoCard {
    background-color: transparent;
  }
}
.InfoHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.InfoHead > div:hover {
  cursor: pointer;
}

.logout-button {
  width: 7rem;
  height: 2rem;
  margin-top: 6rem;
  align-self: flex-end;
}/*# sourceMappingURL=InfoCard.css.map */