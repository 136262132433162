.user {
  border-radius: 0.5rem;
  padding: 0.4rem;
  margin-bottom: 0.5rem;
}

.user:hover {
  background-color: rgba(246, 246, 246, 0.8862745098);
  box-shadow: 5px 10px 18px #d4d4d4;
  transition: all 0.4s;
  cursor: pointer;
}/*# sourceMappingURL=User.css.map */