.LogoSearch {
    display: flex;
    width:100%;

    // flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    // border:1px solid;
   
}



.Search {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    position: relative;
    // border: 1px solid red;
}


.Search>input {

    
    border-radius: 10px;
    border: none;
    outline: none;
    width: 100%;
    font-size: 16px;
    padding: 0.5rem;
    height: 2.5rem;
    background-color: #ffffff;
    opacity: 1;
    // border: 1px solid;
}

.addPostButton>img{
    height:1.5rem;
    width: 1.5rem;

}

@media only screen and (max-width: 600px) {
    .LogoSearch{
        padding: 0.5rem 0.5rem;
    }
    .LogoImage {
        height: 3rem;
        width: 3rem;
        object-fit: cover;

    }
    .Search>input{
        height: 3rem;

    }
}

.s-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: -webkit-linear-gradient(106.23deg, #f99827, #f95f35);
    border-radius: 5px;
    padding: 4px;
    color: white;
    :hover{
        cursor: pointer;  
    }
}


.SearchResults{
    
    padding: 10px;
    position: fixed;
    top: 3.5rem;
    width: 40%;
    display: block;
    border-radius: 10px;
    background-color: white;

    box-shadow:5px 10px 18px #5f5f5f;
    z-index: 2;
    max-height: 50vh;
    overflow: scroll;
    
}

@media only screen and (max-width: 600px) {
    .SearchResults{
        width: 90%;

    }
}
