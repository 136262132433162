.Link{
    color: var(--gray);
    padding:0.3rem;
    border-radius: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;

    // border: 1px solid;
}
@media only screen and (min-width: 800px) {

    .Link:hover{
        // background: rgb(255, 207, 119);
        scale:1.2;
        
        transition: all 0.4s;

    }
    .Link.notification:hover{
        scale: 1;
    }

}

.NavIcons{
    display: flex;
    
    // border: 1px solid red;
    margin-bottom: 1rem;
}

@media only screen and (max-width: 600px) {
    .NavIcons{
        align-items: center;
        flex: 1;

    }

}
.notification{
        gap: 0rem;
        padding: 0rem;
}