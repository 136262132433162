.profileForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.profileForm > div {
  display: flex;
  gap: 1rem;
}

@media only screen and (max-width: 600px) {
  .profileForm > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}/*# sourceMappingURL=ProfileModal.css.map */