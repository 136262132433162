.motherElement {
  gap: 1rem;
  display: flex;
  flex: 1;
}

.Post {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: var(--cardColor);
  border-radius: 1rem;
  gap: 1rem;
  flex: 1;
  overflow: hidden;
  max-width: 100vw;
}

.PostView {
  flex: 1;
}

.PostInfo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.PostInfo:hover .EditButton {
  cursor: pointer;
  display: flex;
}

.sharePostDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sharePostDetails > small {
  font-size: 12px;
  color: var(--gray);
}

.PostHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PicAndName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.7rem;
}

.PostHeader > small {
  font-size: 12px;
  color: var(--gray);
  align-items: flex-end;
}

.PicAndName > img {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
}

.PicAndName > div {
  display: flex;
  flex-direction: column;
}

.PicAndName > div > span {
  font-weight: 600;
}

.time {
  font-size: 12px;
  color: var(--gray);
}

.detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  line-height: 1.6;
  font-size: 14px;
}

.EditButton {
  display: flex;
}

.EditButton > small {
  font-size: 12px;
  color: var(--gray);
  align-items: flex-end;
}

.editOptions {
  display: flex;
}

.PostImage {
  display: flex;
  gap: 1rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.videoPlayer {
  overflow: hidden;
  border-radius: 1rem;
  -o-object-fit: contain;
     object-fit: contain;
}

.PostImageView {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.PostImageView > img {
  max-width: 100%;
  max-height: 20rem;
  height: auto;
  width: auto\9 ;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 0.5rem;
}

.PostImage > img {
  max-width: 100%;
  max-height: 20rem;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 0.5rem;
  height: auto;
  width: auto\9 ;
}

.PostImage > svg {
  position: relative;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
}

.postBottom {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.LikesAndComments {
  display: flex;
  gap: 0.5rem;
}

.PostReact {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.PostReact > img {
  cursor: pointer;
}

.PostReact > div {
  cursor: pointer;
  color: var(--gray);
}
.PostReact > div:hover {
  color: black;
  transition: all 0.4s;
}

.CommentSection {
  display: flex;
  flex-direction: column;
}

.newComment {
  display: flex;
  align-items: center;
  transition-property: all;
  transition-duration: 3s;
}

.newComment > div {
  width: 100%;
}

.comment-button {
  margin-left: 1.2rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  padding: 0.3rem;
}

.newComment > img {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.previousComments {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}/*# sourceMappingURL=Post.css.map */