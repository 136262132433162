.settings {
    position: relative;
    display: grid;
    height: 100vh;
    grid-template-columns: 25% auto 25%;
    gap: 1rem;
}
@media only screen and (max-width: 600px) {
    .settings{
        grid-template-columns: 100%;
    }

}
.settings-container{
    padding:1rem;
    background: var(--cardColor);
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    
    justify-content: flex-start;
    gap: 1.5rem;
    bottom: 0;
}
.settings-container>h2{
    padding-left: 1rem;

}

.list{
    padding-left: 0rem;
}
.listItem{
    padding-left: 1rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    border-radius: 0 2rem 2rem 0rem;
    display: flex;
    gap:1rem;
    flex-direction: row;
    align-items: center;
    
    cursor: pointer;
    &:hover{
        box-shadow: 5px 10px 18px #d4d4d4;
    }

}
.highlight{
    box-shadow: 5px 10px 18px #d4d4d4;
}
.listItem>img{
    height:1.3rem;
    width: 1.3rem;
    object-fit: cover;
}

.errorMessage{
    color:red

}

 .NavBarContainer {
     position: fixed;
     width: 100%;
     height: 4rem;
     background-color: rgba(221, 221, 221, 0.64);
     border-radius: 1rem 1rem 0 0;

     backdrop-filter: blur(15px);
     bottom: 0rem;
     left: 0;

 }