.Posts {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.PostSearch {
    display: flex;
    // flex-direction: column;
    // border:1px solid red;
    gap: 1rem;
    background-color: var(--cardColor);
    padding: 1rem;
    border-radius: 1rem;

}

.filter {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
}

.filter>small {
    font-size: 12px;
    color: var(--gray);
    cursor: pointer;
}

hr {
    width: 85%;
    border: 1px solid var(--hrColor)
}
@media only screen and (max-width: 600px) {
    hr {
        width: 80%;
    }
    .filter>small{
        margin-right: 1rem;
    }
}

.vl {
    height: 150%;
    border-left: 2px solid var(--hrColor)
}