:root {
  --yellow: #f5c32c;
  --orange: #fca61f;
  --black: #242d49;
  --gray: rgba(36, 45, 73, 0.65);
  --profileShadow: 0px 4px 17px 2px rgba(0, 0, 0, 0.25);
  --hrColor: #cfcdcd;
  --cardColor: rgba(255, 255, 255, 0.64);
  --buttonBg: linear-gradient(98.63deg, #f9a225 0%, #f95f35 100%);
  --inputColor: rgba(40, 52, 62, 0.07);
  --photo: #4CB256;
  --video: #4A4EB7;
  --location: #EF5757;
  --deleteButton:#e41c1c ;
  --schedule: #E1AE4A;
  --code: #00BBD3;
}

.parent {
  position: relative;
}

.App {
  overflow: scroll;
  color: var(--black);
  background-color: #f3f3f3;
  /* background-color: red; */
  padding: 1rem 1rem;
  overscroll-behavior-x: contain;
}

@media only screen and (max-width: 600px) {
  .App {
    padding: 0rem;
    padding-top: 0.5rem;
  }
}
.blur {
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  background-color: #a6ddf0;
  /* background-color: #a6f0aa; */
  filter: blur(72px);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  border-radius: 0.5rem;
  background: var(--buttonBg);
  transition: all 100ms ease-out;
}

.button:hover {
  cursor: pointer;
  color: var(--orange);
  background: transparent;
  border: 2px solid var(--orange);
}

::-webkit-scrollbar {
  display: none;
}/*# sourceMappingURL=App.css.map */