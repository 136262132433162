.user {
    border-radius: 0.5rem;
    padding: 0.4rem;
    margin-bottom: 0.5rem;

    // border: 1px solid;
    
    
}
// .follower{

//     object-fit: contain;
//     max-width: 70%;
//     overflow: scroll;
//     // border: 1px solid blue;
// }

// .follower {
//     display: flex;
//     // align-items: center;
//     overflow: scroll;
//     // border: 1px solid green;
// }
// .username{
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     align-items: flex-start;

//     overflow: scroll;
//     border: 1px solid red;
// }



.user:hover{
    background-color: #f6f6f6e2;
    box-shadow: 5px 10px 18px #d4d4d4;
    transition: all 0.4s;
    cursor: pointer;

}