.comment {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  transition: all 0.4s ease-in-out;
}

.comment:hover {
  transition: all 0.4s ease-out;
  cursor: pointer;
}
.comment:hover .options {
  display: flex;
  transition: all 0.4s ease-in-out;
}

.comment > img {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.commentContents {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.commentDetails {
  background-color: rgba(230, 230, 230, 0.64);
  width: 100%;
  border-radius: 1.2rem;
  padding: 0.6rem;
  gap: 0.1rem;
  display: flex;
  flex-direction: column;
}

.commentDetails > div {
  display: flex;
  justify-content: space-between;
}

.commentDetails > div > small {
  font-size: 12px;
  color: var(--gray);
}

.commentDetails > div > span {
  font-weight: 600;
}

.commentDetails > span {
  font-size: 14px;
}

.edit {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.commentInput {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  align-items: flex-start;
}

.commentInput > button {
  margin-left: 1.2rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  padding: 0.3rem;
}

.options {
  display: none;
}

.reactToComment {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding-left: 0.6rem;
}

.reactToComment > small {
  padding: 0.1rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: flex-start;
  gap: 0.4rem;
  cursor: pointer;
}

.commentInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.4rem;
}

.commentInput > img {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.commentReplies {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}/*# sourceMappingURL=Comment.css.map */