.Auth{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    height: fit-content;
    gap:1rem;
    position: relative;

    .formTitle{
        font-size: 18px;
        // margin-bottom: 1rem;
    }

    .ErrorMessage{
        // border:1px solid red;
        color: red;
        font-weight: 600;
    }


    .Webname{
        display: flex;
        flex-direction: column;
        align-items: center;
        // border:1px solid red;

        h1{
            font-size: 2.5rem;
            margin-top: 0;
            margin-bottom: 0;
            color:#3d99e9
        }
        h6{
            font-size: 0.85rem;
            margin-bottom: 0;
        }
    }

    .a-right{
        background-color: var(--cardColor);
        text-align: center;
        padding: 2rem;
        border-radius: 1rem;
    }

}


@media only screen and (max-width: 800px) {
    .Auth {
        .a-right{
            width: 80%;
        }

    }

    .Webname>h6 {
        display: none;

    }

}

.infoInput {
    border: none;
    // border: 1px solid;
    max-width: 100%;
    width: 100%;

    outline: none;
    background-color: var(--inputColor);
    border-radius: 8px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 8px;
    flex: 1;

}

.authForm{

    padding-bottom: 1rem;
    gap:1rem;
    display: flex;
    flex-direction: column;
    width:50vw;

    .firstNameLastName{
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        width: 100%;
    }
}



@media only screen and (max-width: 800px) {

    .authForm{
        width: 100%;

        .firstNameLastName{
            width: 100%;
            flex-direction: column;
        }

    }

    .infoInput {
        padding:15px

    }

}

