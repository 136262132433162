.viewPostModal {
  display: grid;
  max-height: 65vh;
  grid-template-columns: 65% 35%;
  gap: 0.5rem;
  overflow: scroll;
}

.postImage {
  display: flex;
  max-height: 65vh;
  justify-content: center;
  align-items: center;
}

.postImage > img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto\9 ;
  border-radius: 0.5rem;
}

.postDetails {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 65vh;
}

.nameAndImage {
  display: flex;
  flex-direction: column;
}

.nameAndImage > small {
  font-size: 12px;
  color: var(--gray);
}

.description {
  font-size: small;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.LikesAndComments {
  margin-top: 0.5rem;
}

.postContainer {
  display: flex;
  flex: 1;
}/*# sourceMappingURL=ViewPostModal.css.map */