.friendsModal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 80vh;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.buttonsAndInput {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.shareButtons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.slectedUsers {
  display: flex;
  flex-direction: row;
  height: 3rem;
  gap: 1rem;
  white-space: nowrap;
  overflow-x: scroll;
}

.slectedUsers > small {
  color: var(--gray);
}

.friendsList {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  overflow: scroll;
}/*# sourceMappingURL=ViewFriendsModal.css.map */