.Chat {
  position: relative;
  display: grid;
  grid-template-columns: 25% auto;
  gap: 1rem;
}

@media only screen and (max-width: 600px) {
  .Chat {
    gap: 0.2rem;
  }
  .Right-side-chat {
    height: 10%;
  }
}
.Left-side-chat {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.chatListHeading {
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 0.5rem;
  padding-left: 0.6rem;
}

.signOut {
  cursor: pointer;
}

.Chat-container {
  display: flex;
  flex-direction: column;
  background: var(--cardColor);
  border-radius: 1rem;
  padding: 1rem;
  height: 95vh;
  overflow: scroll;
}

@media only screen and (max-width: 600px) {
  .Chat-container {
    padding: 0rem;
  }
}
.textInputConversation {
  margin-top: 0.3rem;
  background-color: rgba(221, 221, 221, 0.64);
  border-radius: 0rem 0rem 1rem 1rem;
}

@media only screen and (min-width: 600px) {
  .textInputConversation {
    background-color: transparent;
  }
}
.Chat-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: scroll;
}

.Right-side-chat {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.online-dot {
  background-color: greenyellow;
  border-radius: 50%;
  position: absolute;
  left: 2rem;
  width: 1rem;
  height: 1rem;
}

.conversation {
  border-radius: 0.5rem;
  padding: 10px;
  overflow: scroll;
}

.conversation:hover {
  cursor: pointer;
}
.conversation:hover .deleteButton {
  display: flex;
}

.conversation > div {
  position: relative;
  overflow-wrap: break-word;
}

.follower {
  display: flex;
  align-items: center;
  overflow: scroll;
}

.chat-header {
  display: flex;
}

@media only screen and (max-width: 600px) {
  .chat-header {
    position: fixed;
    width: 100%;
    height: 5rem;
    z-index: 1;
    border-radius: 1rem 1rem 0 0;
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    top: 0rem;
  }
  .conversationHeader {
    position: fixed;
    width: 100%;
    height: 4rem;
    z-index: 1;
    background-color: rgba(221, 221, 221, 0.64);
    padding-top: 1rem;
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    top: 0rem;
  }
}
.goBack > img {
  height: 1rem;
  width: 1rem;
}

.followerImage {
  border-radius: 50%;
  height: 2.3rem;
  width: 2.3rem;
}

@media only screen and (max-width: 600px) {
  .followerImage {
    height: 2.3rem;
    width: 2.3rem;
  }
}
.chatbox-empty-message {
  display: flex;
  align-self: center;
  justify-content: center;
  font-size: 20px;
}

.chatbox-empty-message > span {
  display: flex;
  align-items: flex-end;
}

.chatbox-empty-message > div {
  display: flex;
  align-items: center;
}

.follower.conversation > div {
  width: 100%;
  display: flex;
}

.chatBox-header {
  display: flex;
  align-items: center;
  height: 100%;
}

.follower.conversation > div > .name {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
}

.NameAndDeleteButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.4rem;
  width: 100%;
  box-sizing: border-box;
}

.latestMessage {
  display: flex;
  width: 100%;
  max-width: 100%;
  align-items: center;
  justify-content: space-between;
  color: rgb(165, 165, 165);
}

.latestMessage > div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

.latestMessage > div:nth-of-type(1) {
  max-width: 80%;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: flex-start;
}

.latestMessage > div:nth-of-type(2) {
  width: -moz-fit-content;
  width: fit-content;
  justify-content: flex-end;
}

.latestMessage > div > span {
  overflow-wrap: break-word;
  width: 100%;
}

.sender {
  color: rgb(165, 165, 165);
}

.receiverDot {
  background-color: rgb(255, 47, 47);
  border-radius: 50%;
  width: 0.2rem;
  height: 0.2rem;
}

.receiver {
  color: rgb(165, 165, 165);
}

.deleteButton {
  display: none;
  -o-object-fit: contain;
     object-fit: contain;
  gap: 0.5rem;
  align-self: flex-start;
}

.deleteButton > img {
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
}

@media only screen and (max-width: 600px) {
  .deleteButton {
    display: flex;
  }
  .deleteButton > img {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.unreadMessage {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border: none;
  background: rgb(255, 0, 0);
  color: white;
  padding: 0.4rem;
  font-size: 9px;
  width: 0.5rem;
  height: 0.5rem;
}

@media screen and (max-width: 768px) {
  .Chat {
    grid-template-columns: 16% auto;
  }
}
.ChatBox-container {
  background: var(--cardColor);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  grid-template-rows: 14vh 62vh 13vh;
  bottom: 0;
}

.chat-header {
  padding: 1rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
}

.chat-body {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.5rem;
  overflow: scroll;
}

@media only screen and (max-width: 600px) {
  .ChatBox-container {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .chat-body {
    padding: 0rem;
    height: 75%;
  }
}
.message {
  background: #8e8e93;
  color: white;
  padding: 0.4rem;
  border-radius: 1rem 1rem 1rem 0;
  max-width: 28rem;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  filter: blur(0);
  gap: 0.5rem;
}

.messageCheckbox {
  filter: blur(0);
  display: flex;
  align-items: center;
  border-radius: 1rem;
  justify-content: flex-start;
  gap: 1rem;
  transition: all 0.3s;
  width: 100%;
  cursor: pointer;
}

.ownMessage {
  justify-content: space-between;
}

.hover {
  padding-left: 0.5rem;
}
.hover:hover {
  background-color: #ecebfa;
}

.message > span:nth-child(2) {
  font-size: 0.7rem;
  color: var(--textColor);
  align-self: end;
}

.own {
  border-radius: 1rem 1rem 0 1rem;
  background: #147efb;
}

.mediaPreview {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.PreviewImage {
  border-radius: 1rem;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 5rem;
}

.PreviewImage > img {
  width: 100%;
  max-height: 20rem;
  border-radius: 0.5rem;
  -o-object-fit: contain;
     object-fit: contain;
}

.PrevieVideo {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 5rem;
  gap: 1rem;
}

.chat-sender {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;
  max-width: 90vw;
  width: 71vw;
}

.chat-sender:hover {
  box-shadow: 5px 10px 18px #d4d4d4;
}

.chat-sender > img {
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
}

.chat-sender > div {
  display: flex;
  gap: 2rem;
}

.chat-sender > div > img {
  cursor: pointer;
  width: 1.1rem;
  height: 1.1rem;
}

.replying {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.messageAttachment {
  display: flex;
  align-items: center;
  border-radius: 1rem 1rem 0rem 0rem;
  padding: 0.4rem;
  gap: 1.5rem;
}

@media only screen and (max-width: 600px) {
  .messageAttachment {
    position: fixed;
    width: 100%;
    height: 4rem;
    bottom: 4.5rem;
  }
}
.attachmentDetails {
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding: 0.2rem 0.2rem 0.2rem 1rem;
  border-radius: 0.5rem;
  color: black;
  box-shadow: inset 0 0 3px;
  overflow-wrap: break-word;
}

.attachmentDetails > div {
  display: flex;
  flex-direction: column;
}

.text {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.attachmentDetails > small:nth-of-type(1) {
  font-weight: 560;
}

.attachmentMedia {
  border-radius: 0.5rem;
  overflow: hidden;
}

.attachmentMedia > img {
  width: 5rem;
  height: auto;
}

.messageSender {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.5rem;
  border-radius: 1rem;
  max-width: 100%;
  width: 100%;
  background-color: var(--cardColor);
}
.messageSender img {
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
}
.messageSender div > img {
  cursor: pointer;
  width: 1rem;
  height: 1rem;
}

.actions {
  padding-left: 1rem;
  padding-right: 1rem;
  height: 3rem;
}

.actions > div {
  display: flex;
  gap: 2rem;
}

@media only screen and (max-width: 600px) {
  .messageSender {
    width: 100%;
    height: 4rem;
    bottom: 0.2rem;
  }
  .messageSender .inputField {
    width: 100%;
    margin-right: 0.5rem;
  }
}
@media only screen and (min-width: 800px) {
  .messageSender:hover {
    box-shadow: 5px 10px 18px #d4d4d4;
  }
}
.PreviewDocument {
  overflow: hidden;
}/*# sourceMappingURL=Chat.css.map */