.Profile {
  position: relative;
  display: grid;
  grid-template-columns: 25% auto 25%;
  gap: 1rem;
}

@media only screen and (max-width: 600px) {
  .Profile {
    grid-template-columns: 100%;
  }
}
.ProfileCenter {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}/*# sourceMappingURL=Profile.css.map */